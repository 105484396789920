import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import moment from 'moment';
import { useAlert } from 'react-alert';

import { UserContext } from '../contexts/user_context';
import {
  fetchBanks,
  fetchBefore30DaysPaymentAggregate,
  fetchCustomerPaymentHistory,
  fetchCustomers,
  fetchDailyPaymentAggregate,
  fetchDisputeDetails,
  fetchDisputes,
  fetchMerchantCategories,
  fetchMonthlyRefunds,
  fetchMonthlyTransactionCount,
  fetchPaymentOptions,
  fetchPaymentOptionsRules,
  fetchPayouts,
  fetchProduct,
  fetchProductCategories,
  fetchRefunds,
  fetchSalesProgress,
  fetchSingleProduct,
  fetchSumRefunds,
  fetchTopSellingProduct,
  fetchTotalPaymentAggregate,
  fetchTotalPendingPayment,
  fetchTotalReceivedPayment,
  fetchTransactionDetails,
  fetchTransactionPaymentHistory,
  fetchTransactions,
  fetchUserBankDetails,
  fetchWalletReport,
  fetchWithdrawals,
  fetchYesterdayPaymentAggregate,
  getAPIKeys,
  getEmailPreferences,
  getSupportDetails,
  getSupportReplies,
  getSupportTickets,
  getUser,
} from '../utils/api';
import { api_auth } from '../utils/helper';

export async function apiCaller(apiFunction, onSuccess, onError, onComplete) {
    try {
        const response = await apiFunction();
        if (response.data) {
            if (!!onSuccess) {
                onSuccess(response.data);
            }
        } else {
            onSuccess("");
        }
    } catch (err) {
        if (err.response) {
            console.warn(err.response.data);
            if (!!onError) {
                onError(err.response);
            }
        }
    } finally {
        if (!!onComplete) {
            onComplete();
        }
    }
}

export function useBaseFetch(
    name,
    baseInitial,
    fetchX,
    dep_load = [],
    alertN = true,
    dep_no_load = [],
    fetchAtFirst = true
) {
    const isMounted = useRef(true);
    const [isLoading, showLoading] = useState(false);
    const [base, setBase] = useState(baseInitial);
    const alert = useAlert();
    const firstLoad = useRef(true);
    useEffect(() => {
        if (!fetchAtFirst && firstLoad.current) {
            return;
        }
        fetchAgain(true);
        return () => {
            isMounted.current = false;
        };
    }, dep_load);

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
        } else {
            fetchAgain(false);
        }
    }, dep_no_load);
    async function fetchAgain(should_load) {
        try {
            if (should_load && isMounted.current) {
                showLoading(true);
            }
            const response = await fetchX();
            if (response.data && isMounted.current) {
                setBase(response.data.data);
            }
        } catch (e) {
            console.log(e);
            if (alertN) {
                alert.error("Unable to fetch " + name);
            }
        } finally {
            if (should_load && isMounted.current) {
                showLoading(false);
            }
        }
    }
    async function fetchNextOrPrev(url) {
        try {
            showLoading(true);
            const response = await api_auth().get(url);
            if (response.data && isMounted.current) {
                setBase(response.data.data);
            }
        } catch (e) {
            console.log(e);
            alert.error("Unable to fetch" + name);
        } finally {
            showLoading(false);
        }
    }
    return [base, isLoading, fetchAgain, fetchNextOrPrev];
}

export function useTransactions(merchant_id, search) {
    return useBaseFetch(
        "transactions",
        {},
        () => fetchTransactions(merchant_id, search),
        [merchant_id],
        true,
        [search]
    );
}

export function useDisputes(search) {
    return useBaseFetch("disputes", {}, () => fetchDisputes(search), [], true, [
        search,
    ]);
}

export function useDisputeDetails(dispute_id) {
    return useBaseFetch(
        "dispute details",
        {},
        () => fetchDisputeDetails(dispute_id),
        [dispute_id]
    );
}

export function useCustomers(search) {
    return useBaseFetch(
        "customers",
        {},
        () => fetchCustomers(search),
        [],
        true,
        [search]
    );
}

export function useProducts(mode, id, search) {
    return useBaseFetch(
        "products",
        {},
        () => fetchProduct(mode, id, search),
        [mode, id],
        true,
        [search]
    );
}

export function useProduct(mode, id, alertN) {
    return useBaseFetch(
        "product",
        {},
        () => fetchSingleProduct(mode, id),
        [mode],
        alertN
    );
}

export function useMerchantDetails() {
    return useBaseFetch("user details", {}, getUser, [], true, [], false);
}

export function usePaymentOptions() {
    return useBaseFetch("payment options", [], fetchPaymentOptions);
}
export function useProductCategories() {
    return useBaseFetch("Product categories", {}, fetchProductCategories);
}
export function useMerchantCategories() {
    return useBaseFetch("Merchant categories", {}, fetchMerchantCategories);
}
export function useBankDetails() {
    return useBaseFetch("bank accounts", {}, fetchUserBankDetails);
}

export function useBanks() {
    return useBaseFetch("banks", [], fetchBanks);
}

export function useEmailPreferences() {
    return useBaseFetch("email preferences", {}, getEmailPreferences);
}

export function useFetchAPIKeys() {
    return useBaseFetch("API keys and Webhooks", {}, getAPIKeys);
}

export function usePaymentOptionRules(merchant_id) {
    return useBaseFetch(
        "Payment Option Rules",
        {},
        () => fetchPaymentOptionsRules(merchant_id),
        [merchant_id],
        true,
        [],
        false
    );
}

export function useWithdrawals() {
    return useBaseFetch("Withdrawals", {}, fetchWithdrawals);
}

export function usePayouts() {
    return useBaseFetch("Payouts", {}, fetchPayouts);
}

export function useRefunds(merchant_id) {
    return useBaseFetch("Refunds", {}, () => fetchRefunds(merchant_id), [
        merchant_id,
    ]);
}

export function useTransactionDetails(ref) {
    return useBaseFetch("Transaction details", {}, () =>
        fetchTransactionDetails(ref)
    );
}

export function useTransactionPaymentHistory(ref) {
    return useBaseFetch("Payment History", {}, () =>
        fetchTransactionPaymentHistory(ref)
    );
}

export function useCustomerPaymentHistory(id) {
    return useBaseFetch("Payment History", {}, () =>
        fetchCustomerPaymentHistory(id)
    );
}

export function useSupportTickets() {
    return useBaseFetch("Support Tickets", {}, getSupportTickets);
}

export function useSupportDetails(id) {
    return useBaseFetch("Support Ticket Details", {}, () =>
        getSupportDetails(id)
    );
}

export function useSupportReplies(id) {
    return useBaseFetch("Support Ticket Replies", {}, () =>
        getSupportReplies(id)
    );
}

const calculate_increment = (now, before) => {
    const n = now || 0;
    const b = before || 0;
    if (n === 0) {
        return 0;
    } else {
        const inc = ((n - b) / b) * 100;
        return Math.round(inc);
    }
};
const parse_wallet_report = (wallet_report) => {
    const report = {
        x_axis: [],
        balance_y: [],
        social_y: [],
    };
    if (wallet_report.wallet_report && wallet_report.wallet_report.length) {
        wallet_report.wallet_report.forEach((rep) => {
            report.balance_y.push(rep.balance / 100);
            report.social_y.push(rep.social_balance / 100);
            report.x_axis.push(moment(rep.date).format("DD-MMM"));
        });
    }
    return report;
};
const parse_sales_progress = (sales_progress) => {
    let report = {
        x_axis: [0, 1],
        y_axis: [0, 0],
        temp: [],
    };
    if (
        sales_progress.successful_payments &&
        sales_progress.successful_payments.length
    ) {
        report.x_axis = [];
        report.y_axis = [];

        sales_progress.successful_payments.forEach((progress) => {
            report.x_axis.push(moment(progress.date).format("MMM-DD"));
            report.temp.push(moment(progress.date).valueOf());
            report.y_axis.push(
                (progress.amount + progress.service_charge) / 100
            );
        });

        const minDate = report.temp[0];
        const maxDate = report.temp[report.x_axis.length - 1];
        const noOfDays = (maxDate - minDate) / (60 * 60 * 24 * 1000);
        const rep = {
            x_axis: [],
            y_axis: [],
        };
        for (let i = 0; i <= noOfDays; i++) {
            const date = minDate + i * 24 * 1000 * 60 * 60;
            const f = report.temp.findIndex((v) => v === date);
            if (f >= 0) {
                // console.log(f);
                // console.log(report.y_axis[f]);
                rep.y_axis.push(report.y_axis[f]);
            } else {
                rep.y_axis.push(0);
            }
            rep.x_axis.push(moment(date).format("MMM-DD"));
        }
        report = rep;
    }
    return report;
};
export function useDashboard() {
    const { user } = useContext(UserContext);
    const [dailyPayment, dailyLoading] = useBaseFetch(
        "Daily Revenue",
        {},
        fetchDailyPaymentAggregate
    );
    const [totalPayment, totalLoading] = useBaseFetch(
        "Total revenue",
        {},
        fetchTotalPaymentAggregate
    );
    const [totalPendingPayment, pendingLoading] = useBaseFetch(
        "Total Pending Payment",
        {},
        fetchTotalPendingPayment
    );
    const [totalReceivedPayment, receivedLoading] = useBaseFetch(
        "Total Received Payment",
        {},
        fetchTotalReceivedPayment
    );
    const [
        monthlyTransaction,
        mTransactionLoading,
    ] = useBaseFetch(
        "Monthly Transaction",
        {},
        () => fetchMonthlyTransactionCount(user.id),
        [user.id]
    );
    const [monthlyRefunds, mRefundsLoading] = useBaseFetch(
        "Monthly Refunds",
        {},
        () => fetchMonthlyRefunds(user.id),
        [user.id]
    );

    const [yesterdayPayment, yesterdayLoading] = useBaseFetch(
        "",
        {},
        fetchYesterdayPaymentAggregate
    );
    const [before30Days, before30Loading] = useBaseFetch(
        "",
        {},
        fetchBefore30DaysPaymentAggregate
    );

    const [topSellingProduct, tspLoading] = useBaseFetch("", [], () =>
        fetchTopSellingProduct()
    );
    const [sum_refunds] = useBaseFetch("", 0, () => fetchSumRefunds);

    const [salesProgress, spLoading] = useBaseFetch(
        "Sales Progress",
        {},
        fetchSalesProgress
    );
    const [walletReport, wLoading] = useBaseFetch(
        "Wallet Progress",
        {},
        fetchWalletReport
    );

    const data = {
        wallet_balance: user.balance || 0,

        daily_revenue: dailyPayment.sum_amount || 0,
        daily_increment: calculate_increment(
            dailyPayment.sum_amount,
            yesterdayPayment.sum_amount
        ),

        total_revenue: totalPayment.sum_amount || 0,
        total_increment: calculate_increment(
            totalPayment.sum_amount,
            before30Days.sum_amount
        ),

        total_pending_payment: totalPendingPayment.count || 0,
        total_received_payment: totalReceivedPayment.count || 0,

        monthly_transaction: monthlyTransaction.count || 0,
        monthly_refunds: monthlyRefunds.count || 0,

        // payment_received:
        //     totalReceivedPayment.sum_amount +
        //         totalReceivedPayment.sum_service_charge || 0,
        payment_received:
            salesProgress.received_payments?.amount +
                salesProgress.received_payments?.service_charge || 0,
        // payment_pending:
        //     totalPendingPayment.sum_amount +
        //         totalPendingPayment.sum_service_charge || 0,
        payment_pending:
            salesProgress.pending_payments?.amount +
                salesProgress.pending_payments?.service_charge || 0,

        top_selling_products: topSellingProduct,
        wallet_report: parse_wallet_report(walletReport),
        sales_progress: parse_sales_progress(salesProgress),
        sum_refunds: sum_refunds,
    };
    return [
        data,
        dailyLoading ||
            totalLoading ||
            pendingLoading ||
            receivedLoading ||
            mTransactionLoading ||
            spLoading ||
            wLoading,
    ];
}
