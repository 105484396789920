import moment from 'moment';

import {
  api_auth,
  api_normal,
} from './helper';

export function register({
    first_name,
    last_name,
    email,
    password,
    business_name,
}) {
    const req_body = {
        email,
        business_name,
        first_name,
        last_name,
        password,
        confirm_password: password,
        type: "merchant",
    };
    return api_normal().post("/register", req_body);
}

export function login({ email, password }) {
    const req_body = {
        email: email,
        password: password,
    };
    return api_normal().post("/login", req_body);
}

export function fetchTransactions(merchant_id, search) {
    return api_auth().get(
        "/transactions?merchant_id=" +
            merchant_id +
            `&search=${search}&orderBy=DESC`
    );
}

export function fetchDisputes(search) {
    return api_auth().get("/disputes?orderBy=DESC&search=" + search);
}

export function fetchDisputeDetails(dispute_id) {
    return api_auth().get(`/disputes/${dispute_id}`);
}

export function fetchCustomers(search) {
    return api_auth().get("/customers?search=" + search);
}

export function fetchProduct(mode, id, search) {
    return api_auth().get(
        "/products?mode=" + mode + "&merchant_id=" + id + `&search=${search}`
    );
}

export function fetchSingleProduct(mode, id) {
    return api_auth().get("/product/" + id + "?mode=" + mode);
}

export function getUser() {
    return api_auth().get("/get-user");
}

export function fetchPaymentOptions() {
    return api_auth().get("/payment_options");
}

export function uploadProducts(products) {
    const formData = new FormData();
    formData.append("products", products);
    return api_auth().post("/products/bulk", formData);
}

export function initiatePasswordReset(email) {
    return api_normal().post("/reset_password/initiate", {
        email: email,
    });
}

export function initiatePasswordChange(email, password, code) {
    return api_normal().post("reset_password/verify", {
        email,
        password,
        confirm_password: password,
        token: code,
    });
}

export function verifyEmail(email, code) {
    return api_normal().post("/verify", {
        email: email,
        verification_code: code,
    });
}

export function resendVerification(email) {
    return api_normal().post("/verify/resend", {
        email,
    });
}

export function fetchUserBankDetails() {
    return api_auth().get("/bank-details");
}

export function fetchBanks() {
    return api_auth().get("/banks");
}

export function createBankDetail(bank_id, bank_account_number, is_default = 0) {
    return api_auth().post("/bank-details", {
        bank_id,
        bank_account_number,
        default: is_default,
    });
}

export function verifyAccountDetails(bank_detail_id) {
    return api_auth().patch("/banks/verify", {
        bank_detail_id,
    });
}

export function setDefaultAccountDetail(bank_detail_id) {
    return api_auth().patch("/banks/set-default", {
        bank_detail_id,
    });
}

export function getEmailPreferences() {
    return api_auth().get("/merchant/preferences/email");
}

export function setEmailPrefeences(preferences) {
    return api_auth().patch("/merchant/preferences/email", preferences);
}

export function updateBusinessProfile(profile) {
    return api_auth().post("/update-merchant-account", {
        address: "",
        business: profile,
    });
}

export function uploadBusinessLogo(file) {
    const form = new FormData();
    form.append("logo", file);
    return api_auth().post("/update-business-logo", form);
}

export function getAPIKeys() {
    return api_auth().get("/keys");
}

export function saveOrResetKeys(
    password,
    reset = false,
    cb_test,
    cb_live,
    wh_test,
    wh_live
) {
    return api_auth().patch("keys", {
        password,
        reset: reset ? 1 : 0,
        callback: cb_live,
        callback_test: cb_test,
        web_hook: wh_live,
        web_hook_test: wh_test,
    });
}

export function changePassword(old_password, new_password) {
    return api_auth().patch("change_password", {
        old_password: old_password,
        password: new_password,
        confirm_password: new_password,
    });
}

export function verifyBusinessAccount(type, reg_no, document) {
    const formData = new FormData();
    formData.append("type", type);
    formData.append("number", reg_no);
    formData.append("document", document);
    return api_auth().post("/business", formData);
}

export function verifyAccountIdentity(dob, bvn) {
    return api_auth().post("/identity", {
        dob,
        bvn,
    });
}

export function fetchPaymentOptionsRules(merchant_id) {
    return api_auth().get(`${merchant_id}/get_rules`);
}

export function setMerchantOptionRules(
    id,
    init_pay,
    is_init_pay_rate,
    repay,
    is_repay_rate,
    max_period,
    max_period_type,
    cancellation_rate,
    cancellation_note
) {
    return api_auth().patch("/payment_settings", {
        initial_payment: init_pay,
        is_initial_payment_rate: is_init_pay_rate,
        repayment_amount: repay,
        is_repayment_amount_rate: is_repay_rate,
        max_period_count: max_period,
        max_period_type: max_period_type,
        payment_option_ids: [id],
        cancellation_payback_rate: cancellation_rate,
        cancellation_note: cancellation_note,
    });
}
export function setMerchantOptionRuleStatus(id, active) {
    return api_auth().patch("/payment_settings", {
        payment_option_ids: [id],
        active,
    });
}

export function setWalletRules(id, active) {
    return api_auth().patch("/payment_settings", {
        payment_option_ids: [id],
        active: active ? 1 : 0,
    });
}

export function updateUserProfile(
    first_name,
    last_name,
    city,
    state,
    phone,
    home_address,
    office_address
) {
    const body = {
        first_name,
        last_name,
        phone,
        city,
        state,
        home_address,
        office_address,
    };
    if (!body.phone) delete body.phone;
    if (!body.office_address) delete body.office_address;
    return api_auth().patch("/update-user-info", body);
}

export function addProduct(name, quantity, price, details, image, category_id) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("quantity", quantity);
    formData.append("price", price);
    formData.append("category_id", category_id);
    if (!!details) formData.append("details", details);
    if (!!image) formData.append("image", image);

    return api_auth().post("/products", formData);
}
export function updateProduct(id, name, quantity, price, details, image) {
    const formData = new FormData();
    formData.append("product_id", id);
    formData.append("name", name);
    formData.append("quantity", quantity);
    formData.append("price", price);
    if (!!details) formData.append("details", details);
    if (!!image) formData.append("image", image);

    return api_auth().post("/products/update", formData);
}

export function setProductWalletRules(pay_id, prod_id, active) {
    return api_auth().patch("/products_payment_options", {
        payment_option_ids: pay_id.map((v) => parseInt(v.trim())),
        product_id: prod_id,
        active: active ? 1 : 0,
    });
}
export function setProductPaymentOptions(
    pay_id,
    prod_id,
    init_pay,
    is_init_pay_rate,
    repay,
    is_repay_rate,
    max_period,
    max_period_type,
    cancellation_rate,
    cancellation_note
) {
    return api_auth().patch("/products_payment_options", {
        payment_option_ids: pay_id.map((v) => parseInt(v.trim())),
        product_id: prod_id,
        initial_payment: init_pay,
        is_initial_payment_rate: is_init_pay_rate,
        repayment_amount: repay,
        is_repayment_amount_rate: is_repay_rate,
        max_period_count: max_period,
        max_period_type: max_period_type,
        cancellation_payback_rate: cancellation_rate,
        cancellation_note: cancellation_note,
    });
}

export function getProductPaymentRules(id) {
    return api_auth().get("/product/" + id + "/get_rules");
}

export function fetchWithdrawals() {
    return api_auth().get("/withdrawals?paginate=50");
}

export function makeWithdrawalRequest(amount, bank) {
    return api_auth().post("/withdraw", {
        bank_details_id: bank,
        amount,
    });
}

export function fetchPayouts() {
    return api_auth().get("withdrawals?status=completed&orderBy=DESC");
}

export function fetchRefunds(merchant_id) {
    return api_auth().get(
        "transactions?merchant_id=" + merchant_id + "&status=payback"
    );
}

export function extendCustomerRefund(ref, date) {
    console.log(ref, date);
    return api_auth().patch("/transactions/" + ref + "/extend", {
        date,
    });
}

export function approveCustomerRefunds(ref) {
    return api_auth().patch("/cancellation_payback_settlement", {
        transaction_ref: ref,
    });
}

export function fetchTransactionDetails(ref) {
    return api_auth().get(`/transactions/${ref}`);
}

export function fetchTransactionPaymentHistory(ref) {
    return api_auth().get("/payments?paginate=5&transaction_ref=" + ref);
}

export function fetchCustomerPaymentHistory(ref) {
    return api_auth().get("/payments?paginate=5&user_id=" + ref);
}

export function confirmCustomerTransaction(id) {
    return api_auth().patch("/payment/confirm/" + id, {
        status: "successful",
    });
}

export function markCompleteTransaction(ref) {
    return api_auth().patch(`/transactions/${ref}/manage`, {
        status: "completed",
        reason: "Transaction verified as completed",
    });
}

export function getSupportTickets() {
    return api_auth().get("/supports");
}

export function getSupportDetails(id) {
    return api_auth().get("/supports/" + id);
}

export function getSupportReplies(id) {
    return api_auth().get(`/supports/${id}/replies?paginate=100`);
}

export function cancelWithdrawalRequest(id, message) {
    return api_auth().patch("/withdrawal/update", {
        withdrawal_id: id,
        status: "cancelled",
        message,
    });
}

export function replySupportTicket(id, message) {
    return api_auth().post(`/supports/${id}`, {
        message,
    });
}

export function closeSupportTicket(id) {
    return api_auth().patch(`/supports/${id}`, {
        confirm: 1,
    });
}

export function fetchTotalPaymentAggregate() {
    return api_auth().get(
        "/payments/aggregate?fields=count,sum_service_charge,sum_amount"
    );
}

export function fetchBefore30DaysPaymentAggregate() {
    const end_date = moment().subtract(30, "days").format("YYYY-MM-DD");
    return api_auth().get(
        `/payments/aggregate?fields=count,sum_service_charge,sum_amount&end_date=${end_date}`
    );
}

export function fetchDailyPaymentAggregate() {
    const start_date = moment().format("YYYY-MM-DD");
    const end_date = moment().add(1, "day").format("YYYY-MM-DD");
    return api_auth().get(
        `/payments/aggregate?fields=count,sum_service_charge,sum_amount&start_date=${start_date}&end_date=${end_date}`
    );
}

export function fetchYesterdayPaymentAggregate() {
    const start_date = moment().subtract(1, "day").format("YYYY-MM-DD");
    const end_date = moment().add(1, "day").format("YYYY-MM-DD");

    return api_auth().get(
        `/payments/aggregate?fields=count,sum_service_charge,sum_amount&start_date=${start_date}&end_date=${end_date}`
    );
}

export function fetchTotalPendingPayment() {
    return api_auth().get(
        "/payments/aggregate?fields=count,sum_service_charge,sum_amount&status=pending"
    );
}
export function fetchTotalReceivedPayment() {
    return api_auth().get(
        "/payments/aggregate?fields=count,sum_service_charge,sum_amount&status=successful"
    );
}

export function fetchMonthlyTransactionCount(merchant_id) {
    const start_date = moment().subtract(30, "days").format("YYYY-MM-DD");
    const end_date = moment().add(1, "day").format("YYYY-MM-DD");
    return api_auth().get(
        `/transactions/count?merchant_id=${merchant_id}&start_date=${start_date}&end_date=${end_date}`
    );
}

export function fetchMonthlyRefunds(merchant_id) {
    const start_date = moment().subtract(30, "days").format("YYYY-MM-DD");
    const end_date = moment().add(1, "day").format("YYYY-MM-DD");
    return api_auth().get(
        `/transactions/count?status=payback&merchant_id=${merchant_id}&start_date=${start_date}&end_date=${end_date}`
    );
}

export function switchMerchantMode(mode) {
    return api_auth().patch("/switch-mode", {
        new_mode: mode,
    });
}

export function deleteBankDetail(bank_detail_id) {
    return api_auth().delete("/banks", {
        data: { bank_detail_id },
    });
}

export function fetchTopSellingProduct() {
    return api_auth().get("/top-selling-products");
}

export function fetchSalesProgress() {
    const start_date = moment().subtract(30, "days").format("YYYY-MM-DD");
    const end_date = moment().add(1, "day").format("YYYY-MM-DD");
    //TODO: normalize end date extension
    return api_auth().get(
        `/sales-progress?start_date=${start_date}&end_date=${end_date}`
    );
}
export function fetchWalletReport() {
    const start_date = moment().subtract(30, "days").format("YYYY-MM-DD");
    const end_date = moment().add(1, "day").format("YYYY-MM-DD");
    return api_auth().get(
        `/wallet-report?start_date=${start_date}&end_date=${end_date}`
    );
}

export function fetchSumRefunds() {
    const start_date = moment().subtract(30, "days").format("YYYY-MM-DD");
    const end_date = moment().add(1, "day").format("YYYY-MM-DD");
    return api_auth().get(
        `/sum-refunds?start_date=${start_date}&end_date=${end_date}`
    );
}

export function fetchProductCategories() {
    return api_auth().get("/products/categories");
}

export function fetchMerchantCategories() {
    return api_auth().get("/merchants/categories");
}

export function getOnlineStoreStatus() {
    return api_auth().get("/online-store-statuses");
}

export function getInterests() {
    return api_auth().get("/interests");
}
export function getOptions() {
    return api_auth().get("/options");
}
export function getIndustries() {
    return api_auth().get("/industries");
}

export function updateUser(data) {
    return api_auth().patch("/update-user", data);
}
