import React, {
  useContext,
  useState,
} from 'react';

import {
  Link,
  useLocation,
} from 'react-router-dom';

import { UserContext } from '../contexts/user_context';

const sidebar_data = [
    {
        id: "dashboard",
        name: "Dashboard",
        link: "/dashboard",
        icon: require("./../assets/icons/side_icons/dashboard.svg"),
    },
    {
        id: "my-business",
        name: "My Business",
        link: "/business/setup",
        icon: require("./../assets/icons/side_icons/store.svg"),
    },
    {
        id: "products",
        name: "My Store",
        link: "/store",
        icon: require("./../assets/icons/side_icons/price.svg"),
    },
    {
        id: "invoice",
        name: "Payment requests",
        link: "/invoices",
        // icon: require("./../assets/icons/side_icons/receipt.svg"),
        icon: require("./../assets/icons/side_icons/refund.svg"),
    },
    {
        id: "transactions",
        name: "Transactions",
        link: "/transactions",
        icon: require("./../assets/icons/side_icons/transfer.svg"),
    },
    {
        id: "withdraw",
        name: "Withdraw",
        link: "/withdrawal",
        icon: require("./../assets/icons/side_icons/Group 1007.svg"),
    },
    {
        id: "payouts",
        name: "Payouts",
        link: "/payouts",
        icon: require("./../assets/icons/side_icons/pay.svg"),
    },
    // {
    //     id: "disputes",
    //     name: "Disputes",
    //     link: "/disputes",
    //     icon: require("./../assets/icons/side_icons/Group 962.svg"),
    // },
    // {
    //     id: "customers",
    //     name: "Customers",
    //     link: "/customers",
    //     icon: require("./../assets/icons/side_icons/Group 974.svg"),
    // },

    // {
    //     id: "refunds",
    //     name: "Refunds",
    //     link: "/refunds",
    //     icon: require("./../assets/icons/side_icons/refund.svg"),
    // },

    // {
    //     id: "verify-profile",
    //     name: "Verify Odio Profile",
    //     link: "/account/verify",
    //     icon: require("./../assets/icons/side_icons/right.svg"),
    // },
    {
        id: "settings",
        name: "Settings",
        hasSide: true,
        icon: require("./../assets/icons/side_icons/settings.svg"),
    },
];
const settings = [
    {
        name: "PAYMENT SETTINGS",
        icon: "settings",
        child: [
            { name: "Enjoy Later", link: "/enjoy-later" },
            { name: "Enjoy Now", link: "/enjoy-now" },
            { name: "Social Payments", link: "/social-pay" },
            { name: "Group Payments", link: "/group-pay" },
            { name: "Wallet Payments", link: "/wallet-pay" },
        ],
    },
    {
        name: "ACCOUNT SETTINGS",
        icon: "person",
        child: [{ name: "Edit Profile", link: "/account/profile" }],
    },
];
export default function SideBar() {
    const [showSide, setShowSide] = useState(false);
    const location = useLocation();
    const { user } = useContext(UserContext);
    const [selected, setSelected] = useState("");
    const closeNav = () => {
        setSelected("");
        setShowSide(false);
    };
    const hideVerified =
        user?.merchant?.status === "verified" &&
        !!user?.bvn &&
        user?.bvn?.length === 11;

    return (
        <>
            <button
                onClick={() => {
                    setShowSide(!showSide);
                    setSelected("");
                }}
                className="md:hidden fixed right-0 z-40 h-20 mr-3"
            >
                <i className="material-icons text-3xl font-medium">menu</i>{" "}
            </button>
            <div
                className={
                    (!showSide ? "side-hide " : "shadow-lg ") +
                    "md:block w-24 sidebar max-h-screen z-50 fixed overflow-y-auto bg-k-indigo text-white pt-5"
                }
            >
                <div className="h-16  mt-4">
                    <Link to="/">
                        <img
                            className="h-12 mx-auto"
                            src={require("./../assets/odio-pay-logo-sky-blue.svg")}
                            alt={"Odio pay Logo"}
                        />
                    </Link>
                </div>
                <div className="text-k2-gray">
                    {sidebar_data.map((data) => {
                        const active = location?.pathname?.startsWith(
                            data.link
                        );
                        return data.hasSide ? (
                            <div
                                id={data.id}
                                onClick={() =>
                                    setSelected(
                                        selected === data.name ? "" : data.name
                                    )
                                }
                                role="button"
                                className={`${
                                    selected === data.name
                                        ? "bg-k-blue text-white"
                                        : ""
                                } py-5 flex-col items-center flex`}
                            >
                                <img
                                    src={data.icon}
                                    className=" h-8 mb-2"
                                    alt={""}
                                />
                                <span className="text-center text-small text-k5-blue whitespace-no-wrap">
                                    {data.name}
                                </span>
                            </div>
                        ) : (
                            <Link
                                onClick={closeNav}
                                className={`${
                                    active ? "bg-k-blue text-white" : ""
                                } py-5 flex-col items-center flex`}
                                to={data.link}
                                id={data.id}
                                key={data.name.toLowerCase()}
                            >
                                <img
                                    src={data.icon}
                                    className=" h-8 mb-2"
                                    alt={""}
                                />
                                <span className="text-center text-small text-k5-blue whitespace-no-wrap">
                                    {data.name}
                                </span>
                            </Link>
                        );
                    })}
                </div>
                <div
                    className={`fixed top-0 left-0 overflow-hidden ml-24 transition-all  duration-500 ${
                        selected === "Settings" ? "w-56  shadow-2xl" : "w-0"
                    } `}
                >
                    <div className="text-k3-gray  bg-white w-56 h-screen  overflow-y-auto ">
                        <div className="shadow px-5 py-6 text-lg">Settings</div>
                        <div className="py-4 space-y-4">
                            {settings.map((st) => (
                                <div>
                                    <div className="flex items-center mb-2 pl-3">
                                        <i className="material-icons text-k3-gray">
                                            {st.icon}
                                        </i>
                                        <h6 className="font-semibold ml-3">
                                            {st.name}
                                        </h6>
                                    </div>
                                    <ul className="ml-12 space-y-2 font-light">
                                        {st.child.map((stc) => (
                                            <li>
                                                <Link
                                                    onClick={closeNav}
                                                    to={stc.link}
                                                >
                                                    {stc.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
