import React from 'react';

import axios from 'axios';

import { config } from './constants';

export function isLoggedIn() {
    const expiry =
        new Date().getTime() <
        new Date(localStorage.getItem(config.TOKEN_NAME + "_expiry")).getTime();
    return !!localStorage.getItem(config.TOKEN_NAME) && expiry;
}

export function api_auth() {
    const instance = axios.create({
        baseURL: config.API_URL,
        headers: {
            Authorization: localStorage.getItem(config.TOKEN_NAME),
        },
    });
    instance.interceptors.response.use(undefined, (err) => {
        if (err.response) {
            if (err.response.status === 401) {
                localStorage.removeItem(config.TOKEN_NAME);
                localStorage.removeItem(config.TOKEN_NAME + "_expiry");
                localStorage.setItem(
                    "redirect_intended",
                    window.location.pathname + window.location.search
                );
                window.location = "/login";
                return;
            }
        }
        return Promise.reject(err);
    });
    return instance;
}

export const api_normal = () => {
    const instance = axios.create({
        baseURL: config.API_URL,
        headers: {
            "Content-Type": "application/json;charset=utf-8",
        },
    });
    return instance;
};

export const parseErrorToArray = (error) => {
    const arr = [];
    Object.entries(error).forEach(([k, v]) => {
        arr.push(...v);
    });
    return arr;
};

export const parseToNaira = (value) => {
    const v = value || 0;
    return (+v).toLocaleString("en-NG", { style: "currency", currency: "NGN" });
};

export const parseMoneyReadable = (num, digits) => {
    const si = [
        // {value: 1E0, symbol: ""},
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "B" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (
        "₦" +
        (num / si[i].value).toFixed(digits).replace(rx, "$1") +
        si[i].symbol
    );
};
export function StatusTag({ type, text }) {
    let color = "";
    switch (type) {
        case "error":
            color = "red";
            break;
        case "warning":
            color = "orange";
            break;
        case "success":
            color = "#00FF44";
            break;
    }
    return (
        <span className="flex items-center" style={{ color: color }}>
            <i className="material-icons text-xs mr-1">help_outline</i> {text}
        </span>
    );
}
