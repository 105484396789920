import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import { useAlert } from 'react-alert';
import {
  Link,
  useHistory,
} from 'react-router-dom';

import { UserContext } from '../contexts/user_context';
import { switchMerchantMode } from '../utils/api';
import { config } from '../utils/constants';
import Loader from './loader';

export default function TopBar({ name }) {
    const { user, fetchUserDetails } = useContext(UserContext);
    useEffect(() => {
        if (!user.id) {
            fetchUserDetails();
        }
    }, [user]);
    return (
        <div className="fixed bg-white z-20 top-0 md:pr-24 w-full flex justify-end sm:justify-between border-b-2 border-gray-200 h-20 items-center">
            <div className="mr-auto ml-4 flex flex-col items-center">
                <Link to="/">
                    <img
                        className="md:hidden"
                        alt={""}
                        src={require("./../assets/LOGO.svg")}
                    />
                </Link>

                <h2 className="md:pl-8 font-bold text-xs md:text-2xl whitespace-no-wrap">
                    {name}
                </h2>
            </div>

            <div className="flex justify-evenly items-center text-gray-600 mr-5">
                {/*<SearchBar />*/}
                <div className="mr-5">
                    <UserMode />
                </div>

                {/* <span>{user?.merchant?.status}</span> */}
                <h5 className="hidden md:block text-sm text-center mr-5">
                    {(user.merchant && user.merchant.business_name) ||
                        "Cool Merchant"}
                </h5>
                <div
                    title={user?.merchant?.status}
                    className={`h-4 rounded-full mr-3 md:mr-5 w-4 ${
                        user?.merchant?.status === "verified"
                            ? "bg-green-500"
                            : "bg-gray-500"
                    }`}
                />
                <Avatar />
                {/* <i className="hidden md:block material-icons px-3">mail</i>
                <i className="hidden md:block material-icons">notifications</i> */}
            </div>
        </div>
    );
}

function Avatar() {
    const [drop, setDrop] = useState(false);
    const { user, onboard } = useContext(UserContext);
    const history = useHistory();
    const logout = () => {
        localStorage.removeItem(config.TOKEN_NAME);
        localStorage.removeItem(config.TOKEN_NAME + "_expiry");
        // history.push("/login");
        window.location.href = "/login";
    };
    return (
        <div className="relative">
            <div
                onClick={() => setDrop(!drop)}
                id="profile-avatar"
                className="rounded-full cursor-pointer mx-3 pr-3 md:pr-0 flex items-center"
            >
                <div
                    className={`cursor-pointer rounded-full h-12 w-12 ${
                        user?.merchant?.business_logo
                            ? ""
                            : "bg-k-pink bg-opacity-25"
                    } relative`}
                >
                    {user?.merchant?.business_logo ? (
                        <img
                            className="rounded-full w-full h-full"
                            alt={""}
                            src={user?.merchant?.business_logo}
                        />
                    ) : (
                        <h6 className="h-full w-full flex items-center justify-center text-xl font-light">
                            {user?.merchant?.business_name?.[0]?.toUpperCase()}{" "}
                        </h6>
                    )}
                </div>
                <i className="material-icons pr-3">expand_more</i>
            </div>
            {drop && (
                <div
                    onMouseLeave={() => setDrop(false)}
                    className="shadow-lg bg-white absolute "
                >
                    <ul className="py-4 px-3 text-xs whitespace-no-wrap">
                        <li className="py-2 cursor-pointer">
                            <Link to={"/account/profile"}>Edit Profile</Link>
                        </li>
                        <li className="py-2">
                            <button onClick={() => onboard.set(true)}>
                                App Helper
                            </button>
                        </li>
                        <li className="py-2 cursor-pointer">
                            <Link to="/support">Support</Link>
                        </li>
                        <li className="py-2 cursor-pointer">Knowledge Base</li>
                        <li onClick={logout} className="py-2 cursor-pointer">
                            Logout
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
}

function SearchBar() {
    return (
        <div className="hidden bg-gray-300 rounded md:flex items-center pl-3 mr-3">
            <i className="material-icons">search</i>
            <input
                className="bg-transparent outline-none h-8 ml-3"
                placeholder="Search..."
            />
        </div>
    );
}

function UserMode() {
    const { user, fetching, fetchUserDetails } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const alert = useAlert();
    const active = (user.merchant && user.merchant.mode !== "test") || false;
    //TODO: turn the switch on by changing to live mode
    const switchMode = async (mode) => {
        try {
            setLoading(true);
            const response = await switchMerchantMode(mode ? "live" : "test");
            if (response.data) {
                await fetchUserDetails();
                alert.success(response.data.message);
                setTimeout(() => {
                    window.history.go();
                }, 1000);
            }
        } catch (e) {
            if (e.response) {
                alert.error(e.response.data.message);
            }
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            {loading && <Loader />}
            <div className="flex flex-row items-center space-x-2">
                <span className="text-base text-center font-normal hidden md:inline-block">
                    {user?.merchant?.mode === "live" ? "Live" : "Sandbox"}
                </span>
                <button
                    onClick={() => switchMode(!active)}
                    className={
                        (active
                            ? "bg-green-500 justify-end"
                            : "bg-gray-300 justify-start") +
                        " mx-auto rounded-full shadow-inner w-10 flex focus:outline-none p-1 border-gray-400 "
                    }
                >
                    <span
                        className={
                            (active ? "bg-white" : "bg-white") +
                            " h-4 w-4 block rounded-full shadow-sm"
                        }
                    />
                </button>
            </div>
        </>
    );
}
