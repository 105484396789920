import React from "react";
import SideBar from "./sidebar";
import TopBar from "./topbar";

export default function Main({ name, children, padChild = true }) {
    return (
        <div className="md:ml-24 pt-20 flex-1 max-h-screen overflow-y-auto main-content">
            <TopBar name={name} />
            <div
                className={
                    (padChild ? "pl-5 md:pl-8 pr-5 " : "") +
                    "text-sm relative main-content-height"
                }
            >
                {children}
            </div>
        </div>
    );
}
