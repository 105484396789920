import {
  useEffect,
  useState,
} from 'react';

// @ts-ignore
import Airtable from 'airtable';

const client = new Airtable({ apiKey: "keyc94gIhGGUJQidL" }).base(
    "apppgK9ZY2Au2NNW6"
);

export function fetchLoginPageImage() {
    return client("odio-customer-login-image")
        .select()
        .all()
        .then((data) => data.map((d) => d.fields));
}
export function useAirtable(func) {
    const [data, setData] = useState([]);
    useEffect(() => {
        func().then((data) => {
            setData(data);
        });
    }, [func]);
    return data;
}
