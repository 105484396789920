import React, {
  useEffect,
  useReducer,
  useState,
} from 'react';

import { useAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tippy';

import Loader from '../../components/loader';
import { PasswordField } from '../../components/password_field';
import { register } from '../../utils/api';

function registerReducer(state, action) {
    switch (action.type) {
        case "field": {
            return {
                ...state,
                [action.field]: action.value,
            };
        }
        case "register": {
            return {
                ...state,
                is_loading: true,
            };
        }
        case "error": {
            return {
                ...state,
                is_loading: false,
                error: action.value,
            };
        }
        case "success": {
            return {
                ...state,
                is_loading: false,
            };
        }
        default: {
            return state;
        }
    }
}

const initialState = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    agree: false,
    is_loading: false,
    business_name: "",
    error: {},
};

export default function Register({ history }) {
    const [state, dispatch] = useReducer(registerReducer, initialState);
    const [hint, showHint] = useState(false);
    const {
        business_name,
        first_name,
        last_name,
        email,
        password,
        agree,
        is_loading,
        error,
    } = state;
    const alert = useAlert();

    useEffect(() => {
        const query = new URLSearchParams(history.location.search);
        const email = query.get("email");
        if (email) {
            dispatch({
                type: "field",
                field: "email",
                value: email,
            });
        }
    }, []);
    const onSubmit = async (e) => {
        e.preventDefault();
        console.log("submitted");
        dispatch({ type: "register" });
        try {
            const response = await register({
                business_name,
                password,
                last_name,
                first_name,
                email,
            });
            console.log(response);
            if (response.data) {
                alert.success(response.data.message, { timeout: 5000 });
                dispatch("success");
                history.push("/verify/email?email=" + email);
                // alert.success("Please login now");
            } else {
                alert.error(response.message);
                dispatch({ type: "error" });
            }
        } catch (e) {
            console.log(e);
            let message = "An error occurred";
            if (e.response && e.response.data) {
                message = e.response.data.message;
                dispatch({ type: "error", value: e.response.data.error || {} });
            }
            alert.error(message);
        }
    };
    return (
        <div className="text-primary">
            {is_loading && <Loader />}
            <div className="flex justify-between items-center my-5">
                <div className="mx-5 md:ml-16">
                    <img
                        src={require("./../../assets/odiopay-logo-navy-blue.svg")}
                        alt={"Odio Logo"}
                    />
                </div>
                <p className="mt-6 md:mt-0 mr-10 flex items-center">
                    <span>
                        Already a member?{" "}
                        <Link className="text-red" to={"/login"}>
                            Sign in
                        </Link>
                    </span>
                    <i className="hidden md:inline material-icons ml-3 p-1 bg-white rounded-full text-black shadow-lg">
                        more_vert
                    </i>
                </p>
            </div>

            <div className="md:flex mt-5">
                <div className="hidden lg:block w-1/2">
                    <img
                        src={require("./../../assets/clint_mckoy_VZfRITK8sKs_unspla.png")}
                        alt=""
                    />
                </div>
                <div className="lg:w-1/2 mx-5 md:mx-auto md:px-10 pr-0 md:pl-10">
                    <div className="container lg:w-5/6">
                        <h3 className="font-bold mb-16  text-2xl">Join Odio</h3>
                        {/*<div className="flex-col md:flex-row space-y-3 md:space-y-0 flex ">*/}
                        {/*    <button*/}
                        {/*        className="bg-blue-100 shadow-lg text-blue-900 font-semibold py-2 px-4 rounded-full md:mr-4">*/}
                        {/*        <img*/}
                        {/*            className="h-10 inline pr-3"*/}
                        {/*            src={require("./../../assets/gimg.png")}*/}
                        {/*        />{" "}*/}
                        {/*        Sign in with Google*/}
                        {/*    </button>*/}
                        {/*    <button*/}
                        {/*        className="bg-blue-100 shadow-lg text-blue-900 font-semibold py-2 px-4 rounded-full">*/}
                        {/*        <img*/}
                        {/*            className="h-10 inline pr-3"*/}
                        {/*            src={require("./../../assets/fimg.png")}*/}
                        {/*        />{" "}*/}
                        {/*        Sign in with Facebook*/}
                        {/*    </button>*/}
                        {/*</div>*/}

                        {/*<div className="mt-8 w-10">*/}
                        {/*    <hr/>*/}
                        {/*</div>*/}

                        {/*<p className="text-gray-500 mt-5 mb-10">*/}
                        {/*    Or sign up with email address*/}
                        {/*</p>*/}

                        <form onSubmit={onSubmit}>
                            <div>
                                <label>Business Name</label>
                                <input
                                    required
                                    name="business_name"
                                    type="text"
                                    value={business_name}
                                    onChange={(e) =>
                                        dispatch({
                                            type: "field",
                                            field: "business_name",
                                            value: e.currentTarget.value,
                                        })
                                    }
                                    className="block w-full bg-blue-100 rounded-full h-12"
                                />
                                {error.business_name && (
                                    <span className="text-red-600">
                                        {error.business_name}
                                    </span>
                                )}
                            </div>
                            <div className="flex mt-5">
                                <div className="w-1/2 mr-8">
                                    <label>First Name</label>
                                    <input
                                        required
                                        name="first_name"
                                        type="text"
                                        value={first_name}
                                        onChange={(e) =>
                                            dispatch({
                                                type: "field",
                                                field: "first_name",
                                                value: e.currentTarget.value,
                                            })
                                        }
                                        className="block w-full bg-blue-100 rounded-full h-12"
                                    />
                                </div>
                                <div className="w-1/2">
                                    <label>Last Name</label>
                                    <input
                                        required
                                        name="last_name"
                                        type="text"
                                        value={last_name}
                                        onChange={(e) =>
                                            dispatch({
                                                type: "field",
                                                field: "last_name",
                                                value: e.currentTarget.value,
                                            })
                                        }
                                        className="block w-full bg-blue-100 rounded-full h-12 "
                                    />
                                </div>
                            </div>
                            <div className="flex mt-5">
                                <div className="w-1/2 mr-8">
                                    <label>Email</label>
                                    <input
                                        required
                                        name="email_address"
                                        type="email"
                                        value={email}
                                        onChange={(e) =>
                                            dispatch({
                                                type: "field",
                                                field: "email",
                                                value: e.currentTarget.value,
                                            })
                                        }
                                        className="block w-full bg-blue-100 rounded-full h-12"
                                    />
                                    {error.email && (
                                        <span className="text-red-600">
                                            {error.email}
                                        </span>
                                    )}
                                </div>
                                <div className="w-1/2">
                                    <label className="flex items-center">
                                        Password{" "}
                                        <PasswordHint
                                            show={hint}
                                            password={password}
                                        />
                                    </label>
                                    <PasswordField
                                        required
                                        name="password"
                                        value={password}
                                        pattern="^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                                        onFocus={() => showHint(true)}
                                        onBlur={() => showHint(false)}
                                        onChange={(e) =>
                                            dispatch({
                                                type: "field",
                                                field: "password",
                                                value: e.currentTarget.value,
                                            })
                                        }
                                        className="block w-full bg-blue-100 rounded-full h-12 "
                                    />
                                    {error.password && (
                                        <span className="text-red-600">
                                            {error.password}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="mt-5">
                                <input
                                    required
                                    className="h-4 w-4 bg-blue-400 mr-3 "
                                    value={agree}
                                    onChange={(e) =>
                                        dispatch({
                                            type: "field",
                                            field: "agree",
                                            value: e.currentTarget.checked,
                                        })
                                    }
                                    type="checkbox"
                                    name="agree"
                                />
                                <span>
                                    Creating an account means you’re okay with
                                    our{" "}
                                    <Link
                                        className="font-semibold"
                                        to="/terms-of-service"
                                    >
                                        Terms of Service
                                    </Link>
                                    ,{" "}
                                    <Link
                                        className="font-semibold"
                                        to="/privacy-policy"
                                    >
                                        Privacy Policy
                                    </Link>
                                    , and our default{" "}
                                    <Link
                                        className="font-semibold"
                                        to="/privacy-policy"
                                    >
                                        Notification Settings
                                    </Link>
                                    .
                                </span>
                            </div>
                            <button
                                className={
                                    (!agree ? "opacity-50" : "") +
                                    " mt-8 rounded-full focus:outline-none bg-primary text-white font-bold text-xl py-3 px-16"
                                }
                            >
                                Create Account
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

// export function PasswordHint({ password, show }) {
//     const [hint, showHint] = useState(false);

//     const validate = (pattern) => {
//         const re = new RegExp(pattern);
//         const test = re.test(password);
//         return test;
//     };
//     return (
//         <Tooltip
//             title="Password hint"
//             position="top"
//             arrow={true}
//             open={show || hint}
//             theme={"light"}
//             html={
//                 <ul className="text-left bg-white text-xs list-disc">
//                     <li className="flex items-center">
//                         <ShowCheckMark show={validate("[A-Z]")} /> Passwords
//                         must contain at least 1 upper case letter
//                     </li>
//                     <li className="flex items-center">
//                         <ShowCheckMark show={validate("[a-z]")} /> Passwords
//                         must contain at least 1 lower case letter
//                     </li>
//                     <li className="flex items-center">
//                         <ShowCheckMark show={validate("[@$!%*#?&]")} />{" "}
//                         Passwords must contain at least 1 special character
//                     </li>
//                     <li className="flex items-center">
//                         <ShowCheckMark show={validate("[0-9]")} /> Passwords
//                         must contain at least 1 number
//                     </li>
//                     <li className="flex items-center">
//                         <ShowCheckMark show={password.length > 8} /> Passwords
//                         must contain at least 8 characters in length
//                     </li>
//                 </ul>
//             }
//         >
//             <i className="material-icons text-sm text-blue-500 pl-2">
//                 help_outline
//             </i>
//         </Tooltip>
//     );
// }

// function ShowCheckMark({ show }) {
//     return show ? (
//         <i className="material-icons text-sm pr-2 text-green-600">
//             check_circle
//         </i>
//     ) : (
//         <i className="pr-6"></i>
//     );
// }

export function PasswordHint({ password, show, align = "left" }) {
    const [hint, showHint] = useState(false);

    const validate = (pattern) => {
        const re = new RegExp(pattern);
        const test = re.test(password);
        return test;
    };
    return (
        <Tooltip
            title="Password hint"
            position="top"
            arrow={true}
            open={show || hint}
            theme={"light"}
            html={
                <ul className="text-left text-xs list-disc space-y-2">
                    <li className="flex items-center">
                        <ShowCheckMark
                            show={validate("[A-Z]")}
                            text={
                                "Passwords must contain at least 1 upper case letter"
                            }
                        />
                    </li>
                    <li className="flex items-center">
                        <ShowCheckMark
                            show={validate("[a-z]")}
                            text="Passwords must contain at least 1 lower case letter"
                        />
                    </li>
                    <li className="flex items-center">
                        <ShowCheckMark
                            show={validate("[@$!%*#?&]")}
                            text="Passwords must contain at least 1 special character"
                        />
                    </li>
                    <li className="flex items-center">
                        <ShowCheckMark
                            show={validate("[0-9]")}
                            text="Passwords must contain at least 1 number"
                        />
                    </li>
                    <li className="flex items-center">
                        <ShowCheckMark
                            show={password.length > 8}
                            text="Passwords must contain at least 8 characters in length"
                        />
                    </li>
                </ul>
            }
        >
            <i className="material-icons text-sm text-blue-500 pl-2">
                help_outline
            </i>
        </Tooltip>
    );
}

function ShowCheckMark({ show, text }) {
    return (
        <>
            {show ? (
                <i className="material-icons text-sm pr-2 text-green-600">
                    check_circle
                </i>
            ) : (
                <i className="pr-6"></i>
            )}
            <span className={show && "font-bold leading-tight"}>{text}</span>
        </>
    );
}

