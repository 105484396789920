// export const config = {
//     API_URL: 'https://staging.odio.com.ng/v1',
//     TOKEN_NAME: 'ODM_Token'
// }

const prodConfig = {
    API_URL: 'https://api.myodiopay.com/v1',
    BASE_URL: 'https://api.myodiopay.com',
}

const devConfig = {
    API_URL: 'https://staging.api.myodiopay.com/v1',
    BASE_URL: 'https://staging.api.myodiopay.com/',
}

const generalConfig = {
    PASSWORD_PATTERN: '^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&\\.]{8,}$',
    TOKEN_NAME: 'ODM_Token',
}
export const config = {
    ...generalConfig,
    ...(process.env.REACT_APP_ENV === 'production' ? prodConfig : devConfig),
}
