import React from 'react';

import { useSingleState } from '../hooks/test_hooks';

export function PasswordField(props) {
    const obscure = useSingleState(true);
    return (
        <div className="relative w-full">
            <input {...props} type={obscure.get ? "password" : "text"} />
            <button
                type="button"
                onClick={() => obscure.set(!obscure.get)}
                className="absolute top-0 bottom-0 right-0 h-full flex justify-center items-center mr-2"
            >
                <span className="material-icons text-lg">
                    {obscure.get ? "visibility" : "visibility_off"}
                </span>
            </button>
        </div>
    );
}
