import {useState} from "react";
import {useAlert} from "react-alert";

export function useApiCall(){
    const [loading, setLoading] = useState(false)
    const alert = useAlert()
    async function call_api (func, successCallback, errorCallback){
        try {
            setLoading(true)
            const response = await func()
            if(response.data){
                alert.success(response.data.message)
                if(!!successCallback){
                    await successCallback(response.data)
                }
            }
            await setTimeout(()=>{}, 5000)
        } catch (e) {
            if(e.response){
                alert.error(e.response.data.message)
                if(!!errorCallback){
                    await errorCallback(e.response)
                }
            }
        }finally {
            setLoading(false)
        }
    }
    return {call_api, loading}
}
