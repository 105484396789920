import React from 'react';

import Chart from 'react-apexcharts';

import Main from '../../components/main';
import { useDashboard } from '../../hooks/fetchHooks';
import {
  parseMoneyReadable,
  parseToNaira,
} from '../../utils/helper';

export default function Dashboard() {
    const [dashboard, dashboardLoading] = useDashboard();
    const WR_series = [
        {
            name: "Social balance",
            data: dashboard.wallet_report.social_y,
        },
        {
            name: "Wallet balance",
            data: dashboard.wallet_report.balance_y,
        },
    ];
    const WR_options = {
        chart: {
            id: "wallet-report",
            animations: {
                enabled: true,
                easing: "easeinout",
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 1000,
                },
            },
        },
        xaxis: {
            categories: dashboard.wallet_report.x_axis,
            labels: {
                show: false,
                style: {
                    fontSize: "8px",
                },
            },
        },
        yaxis: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        grid: {
            show: false,
            borderColor: "#eee",
        },
        plotOptions: {
            bar: {
                startingShape: "flat",
                endingShape: "rounded",
            },
        },
    };

    const Receivable_series = [
        {
            name: "Social balance",
            data: dashboard.wallet_report.social_y,
        },
    ];
    const Receivable_options = {
        chart: {
            id: "receivable-report",
            animations: {
                enabled: true,
                easing: "easeinout",
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 1000,
                },
                // dynamicAnimation: {
                //     enabled: true,
                //     speed: 350
                // }
            },
        },
        stroke: {
            curve: "smooth",
            width: 3,
            colors: ["#aaa"],
        },
        xaxis: {
            categories: dashboard.wallet_report.x_axis,
            labels: {
                show: false,
                style: {
                    fontSize: "8px",
                },
            },
        },
        yaxis: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        grid: {
            show: false,
            borderColor: "#eee",
        },
        plotOptions: {
            bar: {
                startingShape: "flat",
                endingShape: "rounded",
            },
        },
    };

    const Payable_series = [
        {
            name: "Social balance",
            data: dashboard.wallet_report.social_y,
        },
    ];
    const Payable_options = {
        chart: {
            id: "payable-report",
            animations: {
                enabled: true,
                easing: "easeinout",
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 1000,
                },
                // dynamicAnimation: {
                //     enabled: true,
                //     speed: 350
                // }
            },
        },
        stroke: {
            curve: "smooth",
            width: 3,
            colors: ["#aaa"],
        },
        xaxis: {
            categories: dashboard.wallet_report.x_axis,
            labels: {
                show: false,
                style: {
                    fontSize: "8px",
                },
            },
        },
        yaxis: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        grid: {
            show: false,
            borderColor: "#eee",
        },
        plotOptions: {
            bar: {
                startingShape: "flat",
                endingShape: "rounded",
            },
        },
    };

    const Sales_series = [
        {
            name: "Sales",
            data: dashboard.sales_progress.y_axis,
        },
    ];
    const Sales_options = {
        chart: {
            id: "sales-progress",
            parentHeightOffset: 15,
            group: "sparklines",
            sparkline: {
                enabled: true,
            },
            animations: {
                enabled: true,
                easing: "easeinout",
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 1000,
                },
                // dynamicAnimation: {
                //     enabled: true,
                //     speed: 350
                // }
            },
        },
        stroke: {
            curve: "smooth",
            width: 3,
            colors: ["#1a202c"],
        },
        fill: {
            type: "solid",
            opacity: 1,
        },
        colors: ["#e2e8f0"],
        xaxis: {
            type: "category",
            categories: dashboard.sales_progress.x_axis,
            labels: {
                show: false,
                style: {
                    fontSize: "14px",
                },
            },
        },
        yaxis: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        grid: {
            show: false,
            borderColor: "#eee",
        },
    };
    return (
        <Main name={"Dashboard"}>
            {/* {dashboardLoading && <Loader />} */}
            <div className="mt-12">
                <div className="flex items-stretch flex-wrap lg:flex-no-wrap">
                    <div className="flex-1 md:flex-auto flex flex-col md:flex-row justify-center mb-3 lg:mb-0">
                        <DashboardCard
                            color="bg-k2-blue"
                            amount={dashboard.daily_revenue}
                            increment={dashboard.daily_increment}
                            title="DAILY REVENUE"
                        />
                        <DashboardCard
                            color="bg-k-orange"
                            amount={dashboard.total_revenue}
                            increment={dashboard.total_increment}
                            title="TOTAL REVENUE"
                        />
                    </div>

                    <div className="flex-1 md:flex-auto flex justify-center">
                        <SmallDashboardCard
                            color="bg-k3-blue"
                            title={"RECEIVED PAYMENTS"}
                            amount={dashboard.total_received_payment}
                            subtitle={"Transactions"}
                        />
                        <SmallDashboardCard
                            color="bg-k-indigo"
                            title={"PENDING PAYMENTS"}
                            amount={dashboard.total_pending_payment}
                            subtitle={"Transactions"}
                        />
                    </div>
                </div>

                <div className="flex flex-wrap lg:flex-no-wrap lg:justify-between w-full mt-10">
                    <div className="mb-5 md:mb-0 bg-white lg:w-1/3 mr-3 shadow-lg md:shadow-md flex flex-col justify-between rounded-lg">
                        <div className="flex justify-between mx-5 mt-5">
                            <div>
                                <h6 className="font-thin text-2xl">Sales</h6>
                                <span className="text-gray-600 text-xs font-bold">
                                    Sales progress
                                </span>
                            </div>
                            <span className="text-xs text-gray-700">
                                {" "}
                                30 days
                            </span>
                        </div>
                        <div className="flex-1 flex flex-col justify-end">
                            <div className="w-full">
                                <div className="">
                                    <Chart
                                        options={Sales_options}
                                        series={Sales_series}
                                        width={"100%"}
                                        height={180}
                                        type={"area"}
                                    />
                                </div>
                            </div>
                            <div className="bg-gray-300 ">
                                <div className="px-5 py-3 flex whitespace-no-wrap justify-between">
                                    <div className="flex items-center mr-4">
                                        <span className="block bg-primary h-8 w-8 rounded-full mr-2"></span>
                                        <div>
                                            <h6 className="font-medium text-primary text-xl">
                                                {/*{parseToNaira(*/}
                                                {/*    dashboard.payment_received /*/}
                                                {/*        100*/}
                                                {/*)}*/}

                                                {parseMoneyReadable(
                                                    dashboard.payment_received /
                                                        100,
                                                    2
                                                )}
                                            </h6>
                                            <span className="text-gray-600 font-bold text-xs">
                                                Payments Received
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="block bg-red-400 h-8 w-8 rounded-full mr-2"></span>
                                        <div>
                                            <h6 className="font-medium text-primary text-xl">
                                                {/*{parseToNaira(*/}
                                                {/*    dashboard.payment_pending /*/}
                                                {/*        100*/}
                                                {/*)}*/}
                                                {parseMoneyReadable(
                                                    dashboard.payment_pending /
                                                        100,
                                                    2
                                                )}
                                            </h6>
                                            <span className="text-gray-600 font-bold text-xs">
                                                Pending Payments
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col lg:w-1/3 mr-3 text-gray-700">
                        <div className="shadow-md md:shadow flex-1 mb-2 p-3">
                            <h6>Receivable</h6>
                            <div className="">
                                <Chart
                                    options={Receivable_options}
                                    series={Receivable_series}
                                    width={"100%"}
                                    height={120}
                                    type={"line"}
                                />
                            </div>
                        </div>
                        <div className="shadow-md md:shadow flex-1 mt-2 p-3">
                            <h6>Payable</h6>
                            <div className="">
                                <Chart
                                    options={Payable_options}
                                    series={Payable_series}
                                    width={"100%"}
                                    height={120}
                                    type={"line"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 md:mt-0 shadow-md flex flex-col lg:w-1/3 bg-white rounded-lg">
                        <div className="flex-1 flex flex-col justify-between px-5 pt-5">
                            <div>
                                <div className="text-xs flex text-gray-700 justify-between">
                                    <span className="pr-8">Your wallet</span>
                                    <span className="text-green-700">
                                        30 days
                                    </span>
                                </div>
                                <h5 className="text-xl text-primary font-bold">
                                    {parseToNaira(
                                        dashboard.wallet_balance / 100
                                    )}
                                </h5>
                            </div>
                            <div className="">
                                <Chart
                                    options={WR_options}
                                    series={WR_series}
                                    width={"100%"}
                                    height={180}
                                    type={"bar"}
                                />
                            </div>
                        </div>
                        <div className="px-5 pb-8  border-t border-gray-400 pt-3 flex justify-between items-end">
                            <div className="flex-1 mr-10">
                                {/*<span className="block text-purple-900 font-black text-xl">{parseToNaira(dashboard.monthly_refunds / 100)}</span>*/}
                                <span className="block text-purple-900 font-black text-xl">
                                    {parseToNaira(dashboard.sum_refunds / 100)}
                                </span>
                                <span className="text-xs text-gray-600">
                                    Refunds
                                </span>
                                <span className="block w-full bg-gray-100 h-2 mt-1 rounded-full">
                                    <span className="block w-1/2 bg-purple-700 h-full rounded-full"></span>
                                </span>
                            </div>
                            <div className="flex-1">
                                <span className="block text-green-600 font-black text-xl">
                                    {dashboard.monthly_transaction}
                                </span>
                                <span className="text-xs text-gray-600">
                                    Transactions
                                </span>
                                <span className="block w-full bg-gray-100 h-2 mt-1 rounded-full">
                                    <span className="block w-1/2 bg-green-700 h-full rounded-full"></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="my-10">
                    <h6 className="text-lg font-semibold text-primary mb-6">
                        Top Selling Products
                    </h6>
                    <div
                        className={
                            dashboard.top_selling_products.length ? "" : "py-10"
                        }
                    >
                        {!dashboard.top_selling_products.length && (
                            <p className="text-center">
                                No top-selling products yet
                            </p>
                        )}
                        {!!dashboard.top_selling_products.length && (
                            <table className="w-full table table-auto text-gray-900">
                                <tbody>
                                    {dashboard.top_selling_products.map(
                                        (tsp, i) => (
                                            <tr className="border-b-4 border-white">
                                                <td className="px-4 py-3 rounded-l-lg bg-gray-300">
                                                    {i + 1}
                                                </td>
                                                <td className="px-4 py-3 bg-gray-300">
                                                    {tsp.product.name}
                                                </td>
                                                {/* <td className="hidden px-4 py-3 bg-gray-300">
                                                    {moment(tsp.date).format(
                                                        "DD MMM YYYY [at] hh:mm A"
                                                    )}
                                                </td> */}
                                                <td className="px-4 py-3 bg-gray-300">
                                                    {parseToNaira(
                                                        tsp.product.price / 100
                                                    )}
                                                </td>
                                                <td className="px-4 py-3 bg-gray-300">
                                                    {tsp.quantity} Qty
                                                </td>
                                                <td className="px-4 py-3 rounded-r-lg bg-gray-300">
                                                    {parseToNaira(
                                                        (tsp.quantity *
                                                            tsp.product.price) /
                                                            100
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </Main>
    );
}

function DashboardCard({ title, amount, increment, color }) {
    return (
        <div
            style={{ minWidth: "320px" }}
            className={`dashboard-card w-full md:w-full ${color} rounded-lg pl-12 pr-5 text-white py-5 md:mx-2 mb-3 md:mb-0`}
        >
            <div className="relative z-20">
                <h5 className="font-light text-xs ">{title}</h5>
                <div className="flex justify-between items-center">
                    <h6 className="text-xl  font-semibold">
                        {parseToNaira(amount / 100)}
                    </h6>
                    <p className="bg-green-opaque p-4 rounded">{increment}%</p>
                </div>
            </div>
        </div>
    );
}

function SmallDashboardCard({ title, amount, subtitle, color }) {
    return (
        <div
            className={`flex-1 md:flex-auto ${color} mx-1 rounded-lg text-white text-center flex flex-col justify-between p-3`}
        >
            <h5 className="text-xs break-words px-3">{title}</h5>
            <p className="my-2 text-lg font-black">{amount}</p>
            <h6 className="text-xs  font-medium">{subtitle}</h6>
        </div>
    );
}
