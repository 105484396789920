import React from "react";


export const FallBack = () => (<div className="h-screen w-screen flex flex-col items-center justify-center">
    <svg xmlns="http://www.w3.org/2000/svg"  width="67" height="59.785"
         viewBox="0 0 67 59.785">
        <defs>
            <pattern id="pattern" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 0 0">
                <image/>
            </pattern>
        </defs>
        <g id="Group_964" data-name="Group 964" transform="translate(-224 -295.215)">
            <g id="LOGO" transform="translate(278.088 295.932) rotate(91)">
                <rect id="Layer_1" data-name="Layer 1" transform="translate(0.001 0)" fill="url(#pattern)"/>
                <g id="LOGO_05" data-name="LOGO 05" transform="translate(0 0.069)">
                    <path id="Shape_1" data-name="Shape 1"
                          d="M36.836,8.557S28.793-4.033,13.406,1.33c0,0-13.522,4.08-13.405,20.283,0,0-.35,15.154,17.135,19.117a20.36,20.36,0,0,0,19.933-8.276l-1.982-1.515s-6.411,9.092-17.252,5.246c0,0-11.54-3.147-7.927-17.485a10.612,10.612,0,0,0,6.644,11.773s6.411,3.5,12.589-3.614l-1.865-1.632s-3.38,5.129-9.209,3.38c0,0-7.46-2.448-5.712-9.792,0,0,.7-6.761,8.859-6.528,0,0,3.031-.117,5.828,3.38l1.982-1.4S20.75,3.777,8.976,11c0,0-5.362,3.38-6.411,7.927,0,0-.233-8.859,9.559-14.338a18.777,18.777,0,0,1,16.669-.117s4.546,3.031,6.178,5.362Z"
                          transform="translate(0 0)" fill="#13344D"/>
                    <path id="Shape_2" data-name="Shape 2"
                          d="M16.9,6.58,15.037,7.863S10.491.4,0,.519A15.88,15.88,0,0,1,16.9,6.58Z"
                          transform="translate(16.204 4.891)" fill="#13344D"/>
                    <path id="Shape_3" data-name="Shape 3"
                          d="M11.773,0l1.865,1.4S9.092,8.509,0,5.479A13.17,13.17,0,0,0,11.773,0Z"
                          transform="translate(19.351 28.14)" fill="#13344D"/>
                </g>
            </g>
            <text id="Odiopay" transform="translate(224 352)" fill="#13344D" fontSize="16"
                  fontFamily="Graphik-Bold, Graphik, sans-serif" fontWeight="700">
                <tspan x="0" y="0">Odiopay</tspan>
            </text>
        </g>
    </svg>
</div>)

