import React, { lazy } from 'react';

import { useAlert } from 'react-alert';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';

import { Onboarding } from '../components/onboarding';
import SideBar from '../components/sidebar';
import { isLoggedIn } from '../utils/helper';
import Dashboard from './dashboard/dashboard';
import { VerifyProcess } from './dashboard/verify';
import { SetUp } from './setup';

const Transactions = lazy(() => import("./transaction/transactions.js"));
const TransactionDetail = lazy(() =>
    import("./transaction/transaction_detail")
);

const Payouts = lazy(() => import("./payout.js"));
const Disputes = lazy(() => import("./disputes/disputes.js"));
const DisputeDetails = lazy(() => import("./disputes/dispute_details.js"));

const Customers = lazy(() => import("./customer/customers.js"));
const CustomerDetail = lazy(() => import("./customer/customer_detail"));

const Refunds = lazy(() => import("./refunds"));
const Products = lazy(() => import("./product/products"));
const Withdrawals = lazy(() => import("./withdrawal/withdraw"));
const WithdrawalRequest = lazy(() => import("./withdrawal/withdrawal_request"));

const InstallmentPay = lazy(() =>
    import("./payment_options/installmental_pay.js")
);
const SocialPay = lazy(() => import("./payment_options/social_pay.js"));
const GroupPay = lazy(() => import("./payment_options/group_pay"));
const WalletPay = lazy(() => import("./payment_options/wallet_pay"));
const PayLater = lazy(() => import("./payment_options/pay_later"));
const ManageProfile = lazy(() => import("./manage_profile"));

const StoreSetup = lazy(() => import("./store/setup_store"));
const AddProduct = lazy(() => import("./product/add_product"));
const ImportProducts = lazy(() => import("./product/import_product"));
const SetupBusiness = lazy(() => import("./setup_business/setup_business"));
const SupportTicket = lazy(() => import("./support_ticket"));
const SupportDetail = lazy(() => import("./support_detail"));

export const dashboardRoutes = [
    { path: "/setup", component: VerifyProcess },
    { path: "/dashboard", component: Dashboard },
    { path: "/transactions", component: Transactions },
    { path: "/transaction/:trx_ref", component: TransactionDetail },
    { path: "/payouts", component: Payouts },
    { path: "/disputes", component: Disputes },
    { path: "/disputes/:dispute_id", component: DisputeDetails },
    { path: "/customers", component: Customers },
    { path: "/customer/:cus_ref", component: CustomerDetail },
    { path: "/refunds", component: Refunds },
    { path: "/withdrawal", component: Withdrawals },
    { path: "/withdrawal/request", component: WithdrawalRequest },
    { path: "/enjoy-later", component: InstallmentPay },
    { path: "/social-pay", component: SocialPay },
    { path: "/group-pay", component: GroupPay },
    { path: "/wallet-pay", component: WalletPay },
    { path: "/enjoy-now", component: PayLater },
    { path: "/store", component: Products },
    { path: "/store/setup", component: StoreSetup },
    { path: "/store/product/add", component: AddProduct },
    { path: "/store/product/update/:product_id", component: AddProduct },
    { path: "/store/product/import", component: ImportProducts },
    { path: "/account/profile", component: ManageProfile },
    { path: "/business/setup", component: SetupBusiness },
    { path: "/support", component: SupportTicket },
    { path: "/support/detail/:support_id", component: SupportDetail },
    { path: "/invoices", component: lazy(() => import("./invoice/invoices")) },
    {
        path: "/invoice/request",
        component: lazy(() => import("./invoice/create_invoice")),
    },
];
export default function Main() {
    const history = useHistory();
    const alert = useAlert();
    if (!isLoggedIn()) {
        localStorage.setItem(
            "redirect_intended",
            history.location.pathname + history.location.search
        );
        alert.info("Please login proceed");
        return <Redirect to={"/login"} />;
    }
    return (
        <Switch>
            <Route exact path="/" component={SetUp} />
            <Route
                path={dashboardRoutes.map((v) => v.path)}
                component={DashboardRoute}
            />
        </Switch>
    );
}
const DashboardBody = ({ children }) => (
    <div className="flex justify-start bg-k7-gray">
        <Onboarding />
        <SideBar />
        {children}
    </div>
);
const DashboardRoute = () => (
    <DashboardBody>
        <Switch>
            {dashboardRoutes.map((dR) => (
                <Route
                    key={dR.path}
                    path={dR.path}
                    exact
                    component={dR.component}
                />
            ))}
        </Switch>
    </DashboardBody>
);
