import React, {
  createContext,
  useState,
} from 'react';

import { useMerchantDetails } from '../hooks/fetchHooks';

export const UserContext = createContext();

export function UserProvider(props) {
    const [user, isFetching, fetchUserDetails] = useMerchantDetails();
    const [onboard, setOnboard] = useState(false);
    //add other functions later
    //update user probably
    return (
        <UserContext.Provider
            value={{
                user,
                fetching_user: isFetching,
                fetchUserDetails,
                onboard: { get: onboard, set: setOnboard },
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
}
